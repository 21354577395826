@keyframes fade-in-x50 {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-out-x50 {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}

@keyframes fade-in-x50-from-left {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-out-x50-to-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50px);
    opacity: 0;
  }
}

@keyframes fade-in-y30 {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-out-y30 {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}

@keyframes fade-in-y30-center {
  0% {
    transform: translate(-50%, calc(-50% - 30px));
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes fade-out-y30-center {
  0% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, calc(-50% - 30px));
    opacity: 0;
  }
}

@keyframes simple-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes simple-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(100%);
  }
  50% {
    transform: scale(110%);
  }
  100% {
    transform: scale(100%);
  }
}

@keyframes ripple {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes fade-out-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}

@keyframes fade-in-y-10p {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-y-10p {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10%);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  20% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  40% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  60% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  80% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}

@keyframes move-to-right-100p {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes move-from-right-100p {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    position: fixed;
    bottom: -100px;
  }
  100% {
    opacity: 1;
    position: fixed;
    bottom: 0;
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    position: fixed;
    bottom: 0;
  }
  100% {
    opacity: 0;
    position: fixed;
    bottom: -100px;
  }
}

@keyframes shake-horizontally {
  0% {
    transform: translate(3px, 0);
  }
  1.2% {
    transform: translate(-3px, 0);
  }
  2.4% {
    transform: translate(3px, 0);
  }
  3.6% {
    transform: translate(-3px, 0);
  }
  4.8% {
    transform: translate(3px, 0);
  }
  6% {
    transform: translate(-3px, 0);
  }
  7.2% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move-horizontally {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(calc(-50% + 10%), -50%);
  }
  50% {
    transform: translate(-50%, -50%);
  }
  75% {
    transform: translate(calc(-50% - 10%), -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes shake-horizontally-faster {
  0% {
    transform: translate(3px, 0);
  }
  2.4% {
    transform: translate(-3px, 0);
  }
  4.8% {
    transform: translate(3px, 0);
  }
  7.2% {
    transform: translate(-3px, 0);
  }
  9.6% {
    transform: translate(3px, 0);
  }
  12% {
    transform: translate(-3px, 0);
  }
  14.4% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
