html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: ApproachRegular, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overscroll-behavior-x: none;
  overscroll-behavior-y: contain;
  overflow-x: hidden;
  /* disable iOS function that messes up font size inside flex div */
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: black;
  position: fixed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* Fix the "double-bold" effect on iOS, reset for mobile browsers */
  font-weight: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

button {
  -webkit-tap-highlight-color: transparent !important;
  cursor: pointer;
  color: black;
}

#root {
  width: 100%;
  height: 100%;
  background-color: white;
}

body.noselect * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
